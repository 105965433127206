/**
 * @owner @Appboy/composition-infrastructure
 * @fileoverview For support with user personalization using Liquid
 */

import { featureOn } from "@lib/featureFlipper";
import sharedConstants from "@lib/shared_constants.json";

const escapeElement = document.createElement("textarea");
const escape = (html: string): string => {
  escapeElement.textContent = html;
  return escapeElement.innerHTML;
};

const {
  CAMPAIGN_UNIVERSAL_PROPERTIES,
  ALLOWED_DEVICE_ATTRIBUTES_WHITELIST_FF_ON,
  ALLOWED_DEVICE_ATTRIBUTES_WHITELIST_FF_OFF,
  DISPATCH_ID_KEY,
  PREFERENCE_CENTER_URL_ACTUAL_KEY,
  NAMESPACES,
} = sharedConstants.LIQUID_CONSTANTS;

// region Regexes for matching basic liquid
const { OBJECT_START, OBJECT_END, TAG_START, TAG_END, OBJECT, TAG, TAG_OR_OBJECT } = sharedConstants.LIQUID.REGEXES;

const liquidObjectStartRegex = new RegExp(OBJECT_START, "g");
const liquidObjectEndRegex = new RegExp(OBJECT_END, "g");
const liquidTagStartRegex = new RegExp(TAG_START, "g");
const liquidTagEndRegex = new RegExp(TAG_END, "g");
const liquidObjectRegex = new RegExp(OBJECT, "g");
const liquidTagRegex = new RegExp(TAG, "g");
const liquidTagOrObjectRegex = new RegExp(TAG_OR_OBJECT, "g");

// endregion

const liquidObjectStart = "{{";
const liquidObjectEnd = "}}";
const liquidTagStart = "{%";
const liquidTagEnd = "%}";
const liquidVariableStart = "${";
const liquidVariableEnd = "}";

const LIQUID_PARTS = {
  OBJECT_START: liquidObjectStart,
  OBJECT_END: liquidObjectEnd,
  TAG_START: liquidTagStart,
  // n.b. this was TAG_START, I can only assume this was a long-suffering typo?
  TAG_END: liquidTagEnd,
  VARIABLE_START: liquidVariableStart,
  VARIABLE_END: liquidVariableEnd,
};

// TODO: these should not be handled at the constants level.

const ALLOWED_DEVICE_ATTRIBUTES_WHITELIST = featureOn("new_ad_ids")
  ? ALLOWED_DEVICE_ATTRIBUTES_WHITELIST_FF_ON
  : ALLOWED_DEVICE_ATTRIBUTES_WHITELIST_FF_OFF;

const WHITELISTED_VALUES = {
  ...sharedConstants.LIQUID_CONSTANTS.WHITELISTED_VALUES,
  MOST_RECENT_DEVICE_ATTRIBUTES: ALLOWED_DEVICE_ATTRIBUTES_WHITELIST,
  TARGETED_DEVICE_ATTRIBUTES: ALLOWED_DEVICE_ATTRIBUTES_WHITELIST,
  EMAIL_PROPERTIES: [
    PREFERENCE_CENTER_URL_ACTUAL_KEY,
    ...sharedConstants.LIQUID_CONSTANTS.WHITELISTED_VALUES.EMAIL_PROPERTIES,
  ],
  CAMPAIGN_PROPERTIES: [...CAMPAIGN_UNIVERSAL_PROPERTIES, DISPATCH_ID_KEY],
  CAMPAIGN_PROPERTIES_WITHOUT_DISPATCH: CAMPAIGN_UNIVERSAL_PROPERTIES,
};

const ATTRIBUTES_WITH_BRACES: {
  [P in keyof typeof WHITELISTED_VALUES]: string[];
} = Object.fromEntries(
  Object.entries(WHITELISTED_VALUES).map(([key, attrs]) => {
    return [
      key as keyof typeof WHITELISTED_VALUES,
      attrs.map((attr: string) => {
        // This logic is similar to that in template-helpers.js but due to a circular loading issue (that file
        // uses this one), we can't reference it.
        const ns =
          sharedConstants.LIQUID_CONSTANTS.NAMESPACES[
            key as keyof typeof sharedConstants.LIQUID_CONSTANTS.NAMESPACES
          ];
        return `${LIQUID_PARTS.OBJECT_START}${ns ? `${ns}.` : ""}\${${escape(attr)}}${LIQUID_PARTS.OBJECT_END}`;
      }),
    ];
  })
) as {
  [P in keyof typeof WHITELISTED_VALUES]: string[];
};

// Regexes for matching template variables
const endRegex = new RegExp(`\\}(?=.*${liquidTagEndRegex.source}|.*${liquidObjectEndRegex.source})`, "g");

const basicAttrValues = WHITELISTED_VALUES.BASIC_ATTRIBUTES.filter((attr) => attr != "most_recent_location").join(
  "|"
);
const basicAttrRegex = new RegExp(`\\$\\{(${basicAttrValues})${endRegex.source}`, "g");

const getNamespacedPropertiesRegexp = (namespace, properties) => {
  return new RegExp(`${namespace}\\.\\$\\{(${properties.join("|")})${endRegex.source}`, "g");
};

const customAttrNamespace = NAMESPACES.CUSTOM_ATTRIBUTES;
const customAttrRegex = new RegExp(`${customAttrNamespace}\\.\\$\\{(.+?)${endRegex.source}`, "g");

const contentBlocksNamespace = NAMESPACES.CONTENT_BLOCKS;
const contentBlocksRegex = new RegExp(`${contentBlocksNamespace}\\.\\$\\{(.+?)${endRegex.source}`, "g");

const mostRecentDeviceAttrNamespace = NAMESPACES.MOST_RECENT_DEVICE_ATTRIBUTES;
const mostRecentDeviceAttrValues = WHITELISTED_VALUES.MOST_RECENT_DEVICE_ATTRIBUTES.join("|");
const mostRecentDeviceAttrRegex = new RegExp(
  `${mostRecentDeviceAttrNamespace}\\.\\$\\{(${mostRecentDeviceAttrValues})${endRegex.source}`,
  "g"
);

const currentDeviceBeingSentToAttrNamespace = NAMESPACES.TARGETED_DEVICE_ATTRIBUTES;
const currentDeviceBeingSentToAttrValues = WHITELISTED_VALUES.TARGETED_DEVICE_ATTRIBUTES.join("|");
const currentDeviceBeingSentToAttrRegex = new RegExp(
  `${currentDeviceBeingSentToAttrNamespace}\\.\\$\\{(${currentDeviceBeingSentToAttrValues})${endRegex.source}`,
  "g"
);

const eventPropNamespace = NAMESPACES.EVENT_PROPERTIES;
const eventPropRegex = new RegExp(`${eventPropNamespace}\\.\\$\\{(.+?)${endRegex.source}`, "g");

const contextPropNamespace = NAMESPACES.CANVAS_PROPERTIES;
const canvasEntryPropNamespace = NAMESPACES.CANVAS_ENTRY_PROPERTIES;
const contextPropRegex = new RegExp(
  `(?:${contextPropNamespace}|${canvasEntryPropNamespace})\\.\\$\\{(.+?)${endRegex.source}`,
  "g"
);

const apiTriggerEventPropNamespace = NAMESPACES.API_TRIGGER_EVENT_PROPERTIES;
const apiTriggerEventPropRegex = new RegExp(`${apiTriggerEventPropNamespace}\\.\\$\\{(.+?)${endRegex.source}`, "g");

const campaignPropNamespace = NAMESPACES.CAMPAIGN_PROPERTIES;
const campaignPropValues = WHITELISTED_VALUES.CAMPAIGN_PROPERTIES.join("|");
const campaignPropRegex = new RegExp(
  `${campaignPropNamespace}\\.\\$\\{(${campaignPropValues})${endRegex.source}`,
  "g"
);

const workflowPropNamespace = NAMESPACES.WORKFLOW_PROPERTIES;
const workflowPropValues = WHITELISTED_VALUES.WORKFLOW_PROPERTIES.join("|");
const workflowPropRegex = new RegExp(
  `${workflowPropNamespace}\\.\\$\\{(${workflowPropValues})${endRegex.source}`,
  "g"
);

const cardPropNamespace = NAMESPACES.CARD_PROPERTIES;
const cardPropValues = WHITELISTED_VALUES.CARD_PROPERTIES.join("|");
const cardPropRegex = new RegExp(`${cardPropNamespace}\\.\\$\\{(${cardPropValues})${endRegex.source}`, "g");

const emailPropValues = WHITELISTED_VALUES.EMAIL_PROPERTIES.join("|");
const emailPropRegex = new RegExp(`\\$\\{(${emailPropValues})${endRegex.source}`, "g");

const smsPropRegex = getNamespacedPropertiesRegexp(NAMESPACES.SMS_PROPERTIES, WHITELISTED_VALUES.SMS_PROPERTIES);
const whatsAppPropRegex = getNamespacedPropertiesRegexp(
  NAMESPACES.WHATS_APP_PROPERTIES,
  WHITELISTED_VALUES.WHATS_APP_PROPERTIES
);

const productRecommendationNamespace = NAMESPACES.PRODUCT_RECOMMENDATION;
const productRecommendationRegex = new RegExp(
  `${productRecommendationNamespace}\\.\\$\\{(.+?)${endRegex.source}`,
  "g"
);

// ${most_recent_location} is a special case that doesn't follow our syntactic conventions.
// So, it gets special handling here.
const recentLocationRegex = new RegExp(
  `\\$\\{most_recent_location\\}\\.(latitude|longitude)(?=.*${liquidTagEndRegex.source}|.*${liquidObjectEndRegex.source})`,
  "g"
);

const COMPLETION_NAMESPACES: {
  [K in keyof typeof NAMESPACES]: string;
} = Object.fromEntries(
  Object.entries(NAMESPACES).map(([key, namespace]) => {
    return [key, `${liquidObjectStart}${namespace}.${liquidVariableStart}`];
  })
) as {
  [K in keyof typeof NAMESPACES]: string;
};

type FilteredLiquidConstants = Omit<
  typeof sharedConstants.LIQUID_CONSTANTS,
  | "CAMPAIGN_UNIVERSAL_PROPERTIES"
  | "ALLOWED_DEVICE_ATTRIBUTES_WHITELIST_FF_ON"
  | "ALLOWED_DEVICE_ATTRIBUTES_WHITELIST_FF_OFF"
  | "DISPATCH_ID_KEY"
  | "PREFERENCE_CENTER_URL_ACTUAL_KEY"
>;

const LIQUID_CONSTANTS: FilteredLiquidConstants = Object.fromEntries(
  Object.entries(sharedConstants.LIQUID_CONSTANTS).filter(
    ([key]) =>
      ![
        "CAMPAIGN_UNIVERSAL_PROPERTIES",
        "ALLOWED_DEVICE_ATTRIBUTES_WHITELIST_FF_ON",
        "ALLOWED_DEVICE_ATTRIBUTES_WHITELIST_FF_OFF",
        "DISPATCH_ID_KEY",
        "PREFERENCE_CENTER_URL_ACTUAL_KEY",
      ].includes(key)
  )
) as FilteredLiquidConstants;

// this uses LIQUID_CONSTANTS because the LIQUID namespace already exists on shared_constants
const liquidTemplateConstants = {
  ...LIQUID_CONSTANTS,
  WHITELISTED_VALUES,
  LIQUID_REGEXES: {
    OBJECT_START: liquidObjectStartRegex,
    OBJECT_END: liquidObjectEndRegex,
    TAG_START: liquidTagStartRegex,
    TAG_END: liquidTagEndRegex,
    OBJECT: liquidObjectRegex,
    TAG: liquidTagRegex,
    TAG_OR_OBJECT: liquidTagOrObjectRegex,
  },
  LIQUID_PARTS,
  ATTRIBUTES_WITH_BRACES,

  COMPLETION_NAMESPACES,

  TEMPLATE_VARIABLE_REGEXES: {
    BASIC_ATTRIBUTES: basicAttrRegex,
    CUSTOM_ATTRIBUTES: customAttrRegex,
    CONTENT_BLOCKS: contentBlocksRegex,
    MOST_RECENT_DEVICE_ATTRIBUTES: mostRecentDeviceAttrRegex,
    TARGETED_DEVICE_ATTRIBUTES: currentDeviceBeingSentToAttrRegex,
    EVENT_PROPERTIES: eventPropRegex,
    CANVAS_PROPERTIES: contextPropRegex,
    API_TRIGGER_EVENT_PROPERTIES: apiTriggerEventPropRegex,
    CAMPAIGN_PROPERTIES: campaignPropRegex,
    WORKFLOW_PROPERTIES: workflowPropRegex,
    CARD_PROPERTIES: cardPropRegex,
    EMAIL_PROPERTIES: emailPropRegex,
    SMS_PROPERTIES: smsPropRegex,
    WHATS_APP_PROPERTIES: whatsAppPropRegex,
    MOST_RECENT_LOCATION: recentLocationRegex,
    PRODUCT_RECOMMENDATION: productRecommendationRegex,
  },
  // empty string does not appear in the LIQUID constants, so we have to add it by hand
  genders: ["", ...Object.values(sharedConstants.LIQUID.GENDERS)],
};

export default liquidTemplateConstants;
